<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <ContractFilter>
        <v-slide-item class="mt-6">
          <v-btn v-if="isResponsableOrAdmin || hasPermission('add_contract')" @click="openNewContractDrawer()" color="primary">Nouveau contrat</v-btn>
        </v-slide-item>
      </ContractFilter>
    </v-toolbar>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="contract-list">
        <ContractList :key="key" :contrats-loaded="contratsLoaded"/>
      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="contract-view">
        <router-view :key="$route.fullPath" @edit-contract="editContract()"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContractFilter from "@/components/contracts/ContractFilter";
import ContractList from "@/components/contracts/ContractList";
import NewContractSidebar from "@/components/contracts/NewContractSidebar";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  name: "Contrats",
  components: {ContractFilter, ContractList},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    if (this.$store.getters["contracts/allContracts"].length === 0) {
      await this.$store.dispatch("contracts/fetchContracts");
    }
    this.contratsLoaded = true;
  },
  data() {
    return {
      dialog: false,
      operation: "create",
      contractToUpdate: {},
      contrat_id: -1,
      contratsLoaded: false,
      key: 0,
    }
  },
  methods: {
    editContract() {
      this.openDrawerDialog(NewContractSidebar, {
        operation: 'update',
        key: this.selected_contract.id,
        contractToUpdate: this.selected_contract
      });
    },
    openNewContractDrawer() {
      this.openDrawerDialog(NewContractSidebar, {
        operation: 'create',
        key: 0,
        contractToUpdate: null
      });
    }
  },
  computed: {
    selected_contract() {
      return this.$store.getters["contracts/getSelectedContract"];
    }
  }
}
</script>

<style scoped>

</style>